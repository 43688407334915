import React from "react";

import {
  Layout,
  SEO,
  BannerStandard,
  ContentServiceTargetAudience,
  ContentServiceDescription,
  ContentServiceSignUp,
  ContainerBackgroundWhite,
  ContentServiceModule
} from "../../components";
import { ContainerBackground } from "../../components/containers";

import { ServicesData } from "../../components/data";
import { Colors } from "../../components/constants";
import { ListCheckMarks } from "../../components/lists";
import { InternalLinkTypes } from "../../components/links";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

const Pd4castr = () => {
  const service = ServicesData["pd4castr"];
  const data = useStaticQuery(graphql`
    query {
      ImgPd4castrRight: file(
        relativePath: { eq: "services/pd4castr/pd4castr-right.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Img1: file(
        relativePath: { eq: "services/pd4castr/pd4castr-1.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Img2: file(
        relativePath: { eq: "services/pd4castr/pd4castr-2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const ImgPd4castrRight = ({ style }) => {
    return (
      <Img fluid={data.ImgPd4castrRight.childImageSharp.fluid} style={style} />
    );
  };
  const Img1 = ({ style }) => {
    return (
      <Img fluid={data.Img1.childImageSharp.fluid} style={style} />
    );
  };
  const Img2 = ({ style }) => {
    return (
      <Img fluid={data.Img2.childImageSharp.fluid} style={style} />
    );
  };


  return (
    <Layout pageLink={InternalLinkTypes.ServicesVadvisor}>
      <SEO
        title={"pd4castr First Tier"}
        description={
          "Our pdBidr platform encompasses a set of forecasting algorithms, an optimisation engine and bid submission for scheduled and semi-scheduled generators and energy storage systems"
        }
      />
      <BannerStandard title={"pd4castr First Tier"} subTitle={service.description} />
      <ContentServiceDescription
        image={<ImgPd4castrRight />}
        description={
          <div>
            
            <h2
              style={{
                color: Colors.DARK_BLUE,
                fontWeight: "bold",
                fontSize: "1.17rem",
              }}
            >
              Price forecasting model generated by Deep Learning Artificial Intelligence            </h2>
            <h3
              style={{
                color: Colors.LIGHT_BLUE,
                fontSize: "1rem",
                fontWeight: "500",
              }}
            >
              A dedicated team of machine learnings specialists has produced our price forecast models - an output of tuning and training algorithms across years of historical market data.            </h3>
            <ListCheckMarks
              items={[
                "predispatch and week ahead price forecasts",
                "refreshed every 1/2 and 1 hour respectively",
                "forecasts update seconds after new information is published by AEMO", 
                "input data and previous rundatetimes charted",
                "benchmarking and performance metrics",
                "download data via API and other sources"
              ]}
            />
            <h2
              style={{
                color: Colors.DARK_BLUE,
                fontWeight: "bold",
                fontSize: "1.17rem",
              }}
            >
               Inherent in our price forecasts are likely changes to market behaviour because our price forecast models are based on market fundamentals such as supply, demand and transmission capabilities.
            </h2>
            <p>
              This allows you to anticipate likely market outcomes in advance typical changes to market behaviour such as unit commitments and fuel procurement. 
            </p>
          </div>
        }
      />
       <ContainerBackground
          style={{
            textAlign: "center",
            padding: "1rem 10%",
            background: Colors.DARK_BLUE,
            color: "white",
            
          }}
        >
          <p
            style={{
              color: "inherit",
              fontSize: "1.35rem",
              display: "inline-block",
              marginRight: "16px",
            }}
          >
            This service is consumed by trading teams, middle office for calculating risk metrics and limits and back office for day and week ahead revenue projections. 
          </p>
        </ContainerBackground>
     
      <ContentServiceModule
        descriptionList={[
          "Forecast model consumes predispatch and stpasa AEMO data",
          "Half hour resolution for both predispatch and weak ahead",
          "Charts include comparisons with AEMO forecasts and earlier rundatetimes",
          "Charts of main input variables including earlier rundatetimes",
          "Decades of Machine Learning (also known as AI) experience", 
          "Price forecast model is the output of training machine learning algorithms",
          "Model retraining schedule included in knowledge base"
        ]}
        title={"Price forecasts - first tier subscription"}
        image={<Img1 />}
        isBackgroundWhite={true}
        isImageRightAligned={false}
      />
      <ContentServiceModule
        descriptionList={[
          "Performance metrics show absolute mean error since model release",
          "Filter for time of day, rundatetime period and price outcome range",
          "Compare with AEMO performance",
          "Sample count of metric included", 
          "Metrics by region"
        ]}
        title={"Performance metrics"}
        image={<Img2 />}
        isBackgroundWhite={false}
        isImageRightAligned={true}
      />
      <ContentServiceSignUp
        title={service.title}
       
        videoLink={service.watchVideoLink}
      />
      <ContainerBackgroundWhite style={{ padding: "1% 20%" }}>
        
      </ContainerBackgroundWhite>
    </Layout>
  );
};

export default Pd4castr;
